import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function SolutionObesity() {
	
	return (
		<Article
			title='Acne (Face, Chest & Back)'
			takeaway_title='Take following steps to reduce inflammation & strengthen immunity (to cure acne):'
			page_id='Solution - Face Acne'
			read_time='8 mins'
			author='Brij Bhushan'
			image1='/acne.jpg'
			image1_description='Inflammation kicks off secretions from sebaceous gland, leading to acne'
			image2='/A1-A2-cows.jpg'
			image2_description='Examples of A1 & A2 breeds of cow'
			toggle_article='Blog'
			toggle_url='/article/blog_faceacne'
			related_article='Blog : Face Acne'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
				<div className='article_container'>
					<p>
						Eruption of acne occurs when either immunity of a person goes down or inflammation response is triggered (one possible reason for inflammation is hormone imbalance). In the first case, when immunity of a person drops due to unhealthy diet & lifestyle, bacteria present on the skin surface enter pores of the skin and cause infection, which results in acne. In the other case, inflammation kicks off due to few secretions within a sebaceous gland and in the next phase the bacteria on the skin surface, infects the inflamed skin. <span className="super">[1]</span>
					</p>
					<h3 className='article_heading'>How to cure the condition such that the acne goes away for good?</h3>
					<p>
						The answer is simple. Make the necessary changes in diet & lifestyle such that the inflammation goes away and the immunity strengthens.
					</p>
					<h3 className='article_heading'>1. Correct the way you consume milk</h3>
					<p>
						Milk is a diet which is best suited for the consumption of infants. For adults to consume milk and leverage its health benefits, there are few rules that must be followed:
						<ol className='article_list'>
							<li>Treat milk as a complete meal (Don’t drink milk with breakfast or any other meal)</li>
    						<li>Don’t eat anything 2 hrs before & 2 hrs after having milk</li>
    						<li>Consume milk in moderation (1 glass in a day)</li>
    						<li>Prefer Cow’s A2 milk</li>
    						<li>Don’t eat milk or cream with salt</li>
    					</ol>
					</p>
					<p>
						For proper digestion of milk, the human body requires sufficient secretion of an enzyme called lactase. Infants & toddlers secrete a good amount of lactase irrespective of the time of consuming milk. However, as humans grow up their capability to secrete lactase slowly and gradually reduces. Therefore, while young children may have milk along with their breakfast without visible ill-effects, adults should not consume it along with breakfast or any other meal. When an adult consumes milk along with a meal, then the body doesn’t secretes sufficient lactase to digest the milk completely. This results in moving the undigested milk to the large intestine, which eventually increases inflammation in the body. <span className="super">[1]</span>
					</p>
					<p>
						The human body secretes different digestive enzymes for different foods. The way lactase is required to digest milk, similarly different enzymes are required to digest fruits, grains & pulses. Human body is capable of identifying the type of food consumed and accordingly secretes the respective enzyme. If one drinks milk while digestion of wheat (bread) is in progress, then the body detects milk in the middle of digestion of wheat and this causes insufficient secretion of lactase as well as the other enzyme (required to digest wheat). Eventually, neither the wheat nor the milk is digested properly. Therefore, for proper digestion of milk, neither eat anything 2 hrs before drinking milk, nor eat anything 2 hrs after drinking milk. <span className="super">[2]</span>
					</p>
					<p>
						The milk carries several hormones. One of the hormones is called IGF-1. It helps children in growth but it can cause inflammation among adults. Similarly, one of the byproducts from digestion of milk is D-galactose, which is pro-inflammatory and can cause acne. Therefore, milk should be consumed in moderation. One who exercises only for an hour in a day, should not consume more than a glass of milk. However, those who exercise for a total of 3 hours or more in a day, can digest more milk without facing its ill-effects. <span className="super">[4], [5]</span>
					</p>
					<p>
						Unlike the milk from other animals, cow’s milk has few hormones and proteins which are similar to those found in humans. That’s why cow's milk is considered easy to digest by humans and helps in growth. One of the proteins is called beta-casein. Beta-casein is of 2 types; A1 protein & A2 protein. Most of the Indian cow breeds (like Gir, Red sindhi, Tharparkar, Sahiwal & Kankrej) and few western breeds (like Guernsey, Charolais, & Limousin) have mostly A2 protein in their milk, whereas other western breeds like Holstein, Friesian, Ayrshire, and British Shorthorn cows produce milk with roughly equal amounts of A1 and A2 proteins. Few studies have found that humans can easily digest A2 protein, while digestion of A1 protein metabolizes to beta-casomorphon-7 (BCM-7), which can cause inflammation and other problems. Therefore, if one consumes milk that has more A1 protein, then he/she is more likely to have acne. <span className="super">[6], [7], [8], [9]</span>
					</p>
					<p>
						Milk or cream should never be consumed along with salt. Milk has a compound called calcium caseinate. It reacts with stomach acids which is called curdling effect. If salt mixes with milk, then sodium interferes in the curdling and this results in harmful effects like inflammation. Therefore, milk should not be consumed with salty food items, and for the same reason cream should not be added in preparation of curries. However, in those milk products where curdling has happened before digestion (like curd or yoghurt), the addition of salt doesn't cause the problem. <span className="super">[2], [10]</span>
					</p>
					<p>
						Therefore, either don’t consume milk & cream or follow the rules listed above to keep Inflammation in control. If one’s digestion is weak due to illness (like fever) or due to a sedentary lifestyle then prefer not to consume milk or cream. <span className="super">[11]</span>
					</p>
					<h3 className='article_heading'>2. No refined white sugar</h3>
					<p>
						When one consumes refined white sugar, it converts into a high amount of glucose during digestion. Human body can easily absorb the sugar from natural sources like fruits, grains, etc. but to absorb the glucose generated from refined white sugar it requires high secretion of insulin from pancreas. This leads to a condition called Insulin Resistance. Eventually, inflammation in the body increases and immunity drops. Therefore, one should stop consuming refined sugar in any form. This means not eating any food item which has sugar in the ingredients, like biscuits/cookies, cakes, soft drinks, ice-creams, chocolates, candies, etc. However, one can eat sweet fruits like mangoes, oranges, dates, etc. but in moderation. One can also consume food items prepared with jaggery or honey but in moderation. <span className="super">[3]</span>
					</p>
					<p>
						The two changes mentioned above are sufficient to keep the acne away. However, if the problem persists then consider a few more changes to strengthen your digestion. Chew your food properly, never overeat, don’t drink water before & after meals and avoid fruit juices & alcohol. To know more about these changes refer to <Link to='/article/soln_obesity'>this</Link> article.
					</p>
					<h3 className='article_heading'>Conclusion</h3>
					<p>
						These couple of changes in diet and lifestyle will stop the eruption of new acne on the body. Slowly and gradually, existing acne will subside and the skin will become healthy.
					</p>
					<p>
						PS: With these changes in diet, I managed to get rid of my severe acne problem on my face, chest and back, in a few months. To learn more about details of my experience click on: <Link to='/article/blog_faceacne'>here</Link>.
					</p>
					<h3 className='article_heading'>References</h3>
					<ol className="reference_list">
						<li>
							Emil A. Tanghetti, The Role of Inflammation in the Pathology of Acne, The Journal of Clinical and Aesthetic Dermatology, 2013 Sep; 6(9): 27–35. <Link to='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3780801/'>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3780801/</Link>
						</li>
						<li>
							Dr. Bulusu Sitaram, Bhavaprakasa of Bhavamisra, Volume-1, Chapter-5, Chaukhambha Orientalia, Varanasi, Reprint 2020.
						</li>
						<li>
							K. R. Srikantha Murthy, Vagbhata’s Astanga Hrdayam, Volume-1, Chapter-10, Chowkhamba Krishnadas Academy, Varanasi, Reprint 2019.
						</li>
						<li>
							Mark Cappel, David Mauger, Diane Thiboutot, Correlation Between Serum Levels of Insulin-like Growth Factor 1, Dehydroepiandrosterone Sulfate, and Dihydrotestosterone and Acne Lesion Counts in Adult Women, Archives of Dermatology - JAMA Network, 2005;141(3):333-338. <Link to='https://jamanetwork.com/journals/jamadermatology/fullarticle/393279/'>https://jamanetwork.com/journals/jamadermatology/fullarticle/393279/</Link>
						</li>
						<li>
							F William (Bill) Danby, Acne, dairy and cancer, Dermato-Endocrinology, 2009 Jan-Feb; 1(1): 12–16. <Link to='https://doi.org/10.4161/derm.1.1.7124'>https://doi.org/10.4161/derm.1.1.7124</Link>
						</li>
						<li>
							Don Otter, Shelby Anderson, Five things everyone should know about...A2 milk, Wisconsin State Farmer, Jul 3 2019. <Link to='https://www.wisfarmer.com/story/news/2019/07/03/five-things-everyone-should-know-about-a-2-milk/1642645001/'>https://www.wisfarmer.com/story/news/2019/07/03/five-things-everyone-should-know-about-a-2-milk/1642645001/</Link>
						</li>
						<li>
							Ivano De Noni, Richard J. FitzGerald, Hannu J. T. Korhonen, Yves Le Roux, Chris T. Livesey, Inga Thorsdottir, Daniel Tomé, Renger Witkamp, Review of the potential health impact of -casomorphins and related peptides, EFSA Scientific Report (2009) 231, 1-107. <Link to='https://efsa.onlinelibrary.wiley.com/doi/epdf/10.2903/j.efsa.2009.231r'>https://efsa.onlinelibrary.wiley.com/doi/epdf/10.2903/j.efsa.2009.231r</Link>
						</li>
						<li>
							Nutricia, What is A2 protein milk?, <Link to='https://www.nutricia.co.uk/content/dam/dam/amn/local/gb/approved/campaign/a2-infographic.pdf'>https://www.nutricia.co.uk/content/dam/dam/amn/local/gb/approved/campaign/a2-infographic.pdf</Link>
						</li>
						<li>
							MilkDigestion.com, Milk digestion & absorption (adapted from Vander A’s Human physiology: The mechanism of body function), <Link to='https://milkdigestion.com/digestion-absorption/'>https://milkdigestion.com/digestion-absorption/</Link>
						</li>
						<li>
							Mitalee Mittal, Milk and Salt: Compatible or Incompatible, Livepeppy, 2018. <Link to='http://www.livepeppy.com/milk-salt-compatible-incompatible/'>http://www.livepeppy.com/milk-salt-compatible-incompatible/</Link>
						</li>
						<li>
							Claudia Hammond, Does milk settle an upset stomach?, Medical Myths | Human Body, BBC Future, Sep 4 2013. <Link to='https://www.bbc.com/future/article/20130904-does-milk-calm-an-upset-stomach'>https://www.bbc.com/future/article/20130904-does-milk-calm-an-upset-stomach</Link>
						</li>
					</ol>
				</div>
			
			
		</Article>
	);

}

const TakeawayData = [
	{
		takeaway: "If you drink milk, drink only Cow's A2 milk",
	},
	{
		takeaway: "Don't eat anything 2 hrs before & 2 hrs after having milk",
	},
	{
		takeaway: "Don't drink more than 1 glass of milk in a day",
	},
	{
		takeaway: "Don't eat food that has milk or cream along with salt",
	},
	{
		takeaway: "Don't consume sugar & products that contain sugar",
	},
];

const RelatedArticles = [
	{
		title: 'Blog - Face Acne',
		path: '/article/blog_faceacne',
	},
	{
		title: 'Solution - Obesity',
		path: '/article/soln_obesity',
	},
];

